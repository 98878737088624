import React from "react";
import { Link } from "gatsby";
import PageLayout from "../components/layouts/page-layout";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import Container from "../../packages/Container/src";
import Div from "../../packages/Div/src";
import RatioContainer from "../../packages/RatioContainer/src";
import { Grid, GridItem } from "../../packages/Grid/src";
import { Heading } from "../../packages/Typography/src";
var tools = [{
  id: 1,
  title: "Sprite sheet checker",
  slug: "/tools/sprite-sheet-checker"
}];

var ToolsPage = function ToolsPage() {
  return React.createElement(PageLayout, null, React.createElement(SiteHeader, null), React.createElement(Div, {
    bg: "grey.50",
    py: "2.5rem"
  }, React.createElement(Container, null, React.createElement(Heading, {
    size: "xl",
    my: "0"
  }, "Tools"))), React.createElement(Div, {
    style: {
      minHeight: "100vh"
    },
    py: "2.5rem"
  }, React.createElement(Container, null, React.createElement(Grid, {
    gridRowGap: "2.5rem"
  }, tools.map(function (tool) {
    return React.createElement(GridItem, {
      as: Link,
      to: tool.slug,
      key: tool.id,
      gridColumn: ["span 12", "span 6", "span 4"]
    }, React.createElement(RatioContainer, {
      ratio: 2 / 3,
      bg: "grey.50",
      color: "grey.200",
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }
    }, React.createElement("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: "60",
      height: "60",
      viewBox: "0 0 24 24",
      fill: "none",
      stroke: "currentcolor",
      strokeWidth: "1.5",
      strokeLinecap: "square",
      strokeLinejoin: "arcs"
    }, React.createElement("rect", {
      x: "3",
      y: "3",
      width: "18",
      height: "18",
      rx: "2"
    }), React.createElement("circle", {
      cx: "8.5",
      cy: "8.5",
      r: "1.5"
    }), React.createElement("path", {
      d: "M20.4 14.5L16 10 4 20"
    }))), React.createElement(Heading, {
      as: "h3",
      size: "md",
      my: "0"
    }, tool.title));
  })))), React.createElement(SiteFooter, null));
};

export default ToolsPage;